import { Box, Dialog, DialogActions, Fab, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { deviceDetect, isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';
import CONFIG from '../../../config';
import { useBagContext } from '../../../contexts/bagContext';
import { useChatContext } from '../../../contexts/chatContext';
import { useRealTimeProductsContext } from '../../../contexts/realTimeProductsContext';
import { useSnackbarContext } from '../../../contexts/snackBarContext';
import { useSocketContext } from '../../../contexts/socketContext';
import { useStreamContext } from '../../../contexts/streamContext';
import { errorTypes, somaEvents, useEventDispatch } from '../../../events';
import { useEvent } from '../../../events/useEvent';
import { buildBagByUrl } from '../../../helpers/bagHelper';
import { orderMessagesByDescendingTime } from '../../../helpers/chatHelper';
import { getDevice } from '../../../helpers/eventHelper';
import { save } from '../../../helpers/localStorageHelper';
import { CartSimulationContext, LocalStorageContext } from '../../../hooks/contexts';
import useShowCasingRefresher from '../../../hooks/useShowCasingRefresher';
import SomaliveAPIClient from '../../../services/somaliveAPI';
import { MobileShare, VimeoPlayer } from '../../atoms';
import { MuteButton, SomaChatCard, SomaChatForm, SomaChatMessageDisplay, SomaFAQ } from '../../molecules';
import NewFeature from '../../molecules/NewFeature';
import ReactionAssets from '../../molecules/ReactionAssets';
import { FigureButton } from '../../molecules/SomaClickable';
import {
  LiveProduct,
  ProductDetails,
  SomaBag,
  SomaDrawer,
  SomaMenu,
  SomaNavigation,
  SomaProductsList,
  SomaSurvey,
} from '../../organisms';
import AnchorSize from '../../organisms/AnchorSize';
import { LiveTemplate } from '../../templates';
import HomePage from '../Home/home.page';
import Style from './style';
// import ShoesForm from '../../organisms/ShoesForm';

const Live = (props) => {
  const { user, setShowLGPD, reactionSocketState } = props;

  const { realTimeProductsState } = useRealTimeProductsContext();

  const {
    socket,
    receivedMessage,
    setReceivedMessage,
    surveyQuestion,
    setSurveyQuestion,
    setAlternative,
    setIsAnswered,
  } = useSocketContext();
  const { chatMessages, setChatMessages, chatBadgeCount, setChatBadgeCount } = useChatContext();
  const { setBagState } = useBagContext();
  const [volume, setVolume] = useState(0);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [badgeCount, setBadgeCount] = useState(null);
  const closeModalIcon = `${CONFIG.assetsUrl}/common/icons/modal-close-icon.svg`;
  const portrait = useMediaQuery('(orientation: portrait)');

  const endedSurveys = surveyQuestion?.filter((survey) => !survey.active) || [];
  const activeSurveys = surveyQuestion?.filter((survey) => survey.active) || [];

  const updatePoll = useCallback(
    (data) => {
      if (!data) return;
      setSurveyQuestion(data);
      setIsAnswered(false);
      setAlternative(-1);
    },
    [setAlternative, setIsAnswered, setSurveyQuestion]
  );

  useEffect(() => {
    if (!socket) return;
    socket.emit('getAllSurveys', updatePoll);
  }, [socket, updatePoll]);

  useEffect(() => {
    updatePoll(surveyQuestion);
  }, [surveyQuestion, updatePoll]);

  const { streamContent } = useStreamContext();
  const { sendSnack } = useSnackbarContext();
  const {
    buttons = [],
    brand,
    stream_name: streamName,
    is_live: isLive,
    sales_channel: salesChannel,
    video,
    has_chat: hasChat,
  } = streamContent;
  const { isRerun } = video;
  const isPipSuported = ReactPlayer.canEnablePIP(video.url);
  const isOnInstagram = deviceDetect().ua?.includes('Instagram');
  const showPiP = isPipSuported && !isOnInstagram;

  const [isProductsDrawerOpen, setIsProductsDrawerOpen] = useState(false);
  const [isProductDetailsDrawerOpen, setIsProductDetailsDrawerOpen] = useState(false);
  const [isSurveyDrawerOpen, setIsSurveyDrawerOpen] = useState(false);
  const [isAnchorSizeDrawerOpen, setIsAnchorSizeDrawerOpen] = useState(false);
  const [isBagDrawerOpen, setIsBagDrawerOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isSurveyOpen, setIsSurveyOpen] = useState(false);
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  // const [isShoesOpened, setShoesOpened] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [productDetails, setProductDetails] = useState({});
  const [name, setName] = useState();
  const [simulatedCart, setSimulatedCart] = useState();
  const scrollTarget = useRef();
  const mobileScrollTarget = useRef();
  const reactionRef = useRef();
  const userStore = useContext(LocalStorageContext);
  const [email, setEmail] = useState();
  const bagProducts = userStore?.get('bag');
  const [reacted, setReacted] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const messagesRef = useRef(chatMessages);
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const chatIcon = { src: `${CONFIG.assetsUrl}/farm/icon/chat.svg`, alt: 'chatIcon' };

  const theme = useTheme();
  const dialogClasses = Style.useDialogStyle();
  const chatClasses = Style.useGridChatStyle();
  const iconButtonClasses = Style.useIconButtonStyle();
  const dialogContainerClasses = Style.useDialogContainerStyles();
  const reactionButtonClasses = Style.useReactionButtonStyles(reacted, theme);
  const pipButtonClasses = Style.usePipButtonStyles(theme);

  const chatActiveSelector = hasChat ?? theme.chat.active;
  const isChatEnabled = Boolean(chatActiveSelector);

  const isSurveyEnabled = theme.survey.active;

  const reactionActiveSelector = reactionSocketState;
  const isReactionEnabled = Boolean(reactionActiveSelector);

  const dispatchEvent = useEventDispatch();
  const storageId = brand.name + streamName;

  const Somalive = useMemo(() => new SomaliveAPIClient(streamName), [streamName]);

  useEffect(() => {
    if (activeSurveys.length > 0 || endedSurveys.length > 0) {
      setBadgeCount(1);
    }
  }, [activeSurveys.length, endedSurveys.length]);

  useEffect(() => {
    if (isSurveyOpen && endedSurveys.length > 0) {
      setBadgeCount(null);
    }
  }, [isSurveyOpen, endedSurveys.length]);

  const allProducts = useMemo(
    () =>
      buttons
        .map((button) => button.products)
        .reduce((accumulator, currentProductsList) => accumulator.concat(currentProductsList), []),
    [buttons]
  );

  const productsBagFilter = bagProducts.reduce((accumulator, bagProduct) => {
    allProducts.forEach((prod) => {
      if (
        prod.id === bagProduct.productId &&
        prod.items.find((item) => item.isAvailable && bagProduct.bagInfo.selectedItem.sku === item.sku)
      ) {
        accumulator.push(bagProduct);
      }
    });
    return accumulator;
  }, []);

  userStore.set('bag', productsBagFilter);

  useEvent(somaEvents.onProductJumpedToTime, () => setIsProductsDrawerOpen(false));

  useShowCasingRefresher();

  const { showCasing } = realTimeProductsState;

  const mutableChatCounter = useRef(0);

  useEffect(() => {
    if (!receivedMessage) return;

    setChatMessages((messages) =>
      orderMessagesByDescendingTime([...messages, { ...receivedMessage, userRead: false }])
    );
    setReceivedMessage(null);
  }, [receivedMessage, setChatMessages, setReceivedMessage, socket, userStore]);

  useEffect(() => {
    mutableChatCounter.current = chatBadgeCount;
  }, [chatBadgeCount]);

  useEffect(() => {
    const chat = userStore.get('chat');
    setEmail(userStore.get('email'));
    setName(userStore.get('name'));

    if (userStore.get('email')) {
      setLoggedIn(true);
    }

    if (chat) {
      const messages = chat.get('messages');
      setChatMessages(orderMessagesByDescendingTime(messages));
    }
  }, [setChatMessages, storageId, userStore]);

  useEffect(() => {
    setShowLGPD(true);
  }, [setShowLGPD]);

  const saveUserData = (object) => {
    userStore.set('email', object.email);
    userStore.set('name', object.name);
    setEmail(object.email);
    setName(object.name);
    setLoggedIn(true);
  };

  const sendMessage = (messageObj) => {
    if (!socket) return;
    const chat = userStore.get('chat');
    const privateRoom = chat.get('privateRoom');
    const newMessage = {
      ...messageObj,
      privateRoom,
    };

    socket.emit('sendMessage', newMessage, ({ ok, message }) => {
      if (ok) {
        setChatMessages((messages) => orderMessagesByDescendingTime([...messages, { ...message, userRead: false }]));
        saveUserData(newMessage);
        dispatchEvent(somaEvents.onChatMessageSent, {
          name: newMessage.name,
          email: newMessage.email,
          message: newMessage.text,
        });
      }

      if (!ok) {
        dispatchEvent(somaEvents.onError, {
          type: errorTypes.requestError,
          message: `Failed to send message: ${message}`,
          path: 'Live/index.js -> socket.emit(sendMessage)',
          device: getDevice(),
        });
        sendSnack(`Erro, mensagem não pode ser enviada devido ao erro: ${message}`);
      }
    });
  };

  useEffect(() => {
    messagesRef.current = chatMessages;
    const unreadedMessages = chatMessages.reduce(
      (accumulator, currentMessage) => (currentMessage.userRead ? accumulator : accumulator + 1),
      0
    );
    const chat = userStore.get('chat');
    chat.set('messages', chatMessages);
    userStore.set('chat', chat);
    save(userStore);
    setChatBadgeCount(unreadedMessages);
  }, [chatMessages, setChatBadgeCount, userStore]);

  const sendEmail = async (emailCaptacao) => {
    const payload = { email: emailCaptacao, brand: brand.name, streamname: streamName };

    return Somalive.saveUserEmail(payload)
      .request.then((customer) => {
        userStore.set('email', customer.email);
        userStore.set('name', customer.name);
        setEmail(customer.email);
        setName(customer.name);
        save(userStore);
        return Promise.resolve(customer);
      })
      .catch((error) => {
        dispatchEvent(somaEvents.onError, {
          type: errorTypes.requestError,
          message: error.message,
          path: 'Live/index.js -> Somalive.saveUserEmail()',
          device: getDevice(),
          stack: error.stack,
        });
        if (/failed to fetch/i.test(error.message)) {
          return Promise.reject(new Error(theme.system.messages.email.failedToFetch));
        }
        if (/aborted/.test(error.message)) {
          Promise.reject(new Error('request timeout'));
        }
        return Promise.reject(new Error(theme.system.messages.email.postError));
      });
  };

  const renderFaqComponent = () => (
    <>
      <DialogActions classes={dialogClasses}>
        <Fab
          classes={iconButtonClasses}
          onClick={() => {
            setIsFaqOpen(false);
          }}
          disableRipple
          disableFocusRipple
        >
          <img src={closeModalIcon} alt="close_modal" width="12px" height="12px" />
        </Fab>
      </DialogActions>
      <SomaFAQ />
    </>
  );

  const renderChatComponent = () => (
    <Grid container direction="column" className={chatClasses.root}>
      {Boolean(email) && !keyboardVisible && (
        <Grid item className={chatClasses.messagesWrapper}>
          <SomaChatMessageDisplay showEmail={!!user} />
        </Grid>
      )}
      <Grid item className={chatClasses.formWrapper}>
        <SomaChatForm
          setKeyboardVisible={setKeyboardVisible}
          presetEmail={email}
          presetName={name}
          isLoggedIn={loggedIn}
          onSend={sendMessage}
          saveUserData={saveUserData}
          setLoggedIn={setLoggedIn}
        />
      </Grid>
    </Grid>
  );

  const closeAllDrawers = () => {
    setIsBagDrawerOpen(false);
    setIsProductsDrawerOpen(false);
    setIsProductDetailsDrawerOpen(false);
    setIsSurveyDrawerOpen(false);
    setIsFaqOpen(false);
    setIsAnchorSizeDrawerOpen(false);
    // setShoesOpened(false);
  };

  useEvent(somaEvents.closeAllDrawers, () => closeAllDrawers());
  useEvent(somaEvents.onOpenProductDetails, (payload) => {
    setProductDetails(payload);
    setIsProductDetailsDrawerOpen(true);
  });

  useEffect(() => {
    buildBagByUrl(allProducts, userStore).then((updatedBagProducts) => {
      const cartSimulationData = {
        brandName: brand.name,
        exceptions: theme.bagSimulationExceptions,
        salesChannel,
        setSimulatedCart,
      };
      setBagState({ products: updatedBagProducts.bag, cartSimulationData });
    });
  }, [allProducts, setBagState, userStore, brand.name, theme.bagSimulationExceptions, salesChannel]);
  const callbackPosTapume = useRef();

  const homePageProps = {
    onClick: () => {
      setShowWelcome(false);
      setVolume(1);
    },
    liveActive: !!isLive,
    sendEmail,
    email,
    onNavigateToSharedProduct: (sharedProduct) => {
      setShowWelcome(false);
      setVolume(0);
      callbackPosTapume.current = () => {
        dispatchEvent(somaEvents.onOpenProductDetails, sharedProduct);
      };
    },
    onNavigateToBag: () => {
      setShowWelcome(false);
      setVolume(0);
      setTimeout(() => {
        if (!isMobile) {
          setIsBagDrawerOpen(true);
        }
      }, 1000);
    },
  };

  useEffect(() => {
    if (showWelcome === false && callbackPosTapume.current) {
      callbackPosTapume.current();
    }
  }, [showWelcome]);

  const chatButtonProps = {
    figure: <img src={theme.chat.icon.src} alt={chatIcon.alt} height="13.22" width="16px" />,
    badgeCount: chatBadgeCount,
    buttonStyles: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: theme.chat.borderRadius,
      height: 48,
      width: 48,
      opacity: chatBadgeCount > 0 ? 1 : 0.7,
      borderColor: 'transparent',
    },
    badgeStyles: {
      left: 6,
      top: -9,
    },
    buttonProps: {
      onClick: () => {
        setIsChatOpen(true);
        dispatchEvent(somaEvents.onChatShown);
      },
    },
  };

  const surveyButtonProps = {
    buttonStyles: {
      zIndex: 1000,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: theme.survey.shape.borderRadius,
      height: 32,
      width: 32,
      minWidth: 32,
      opacity: badgeCount > 0 ? 1 : 0.7,
      borderColor: 'transparent',
      fontStyle: 'normal',
      fontFamily: 'icomoon',
      fontVariant: 'normal',
      textTransform: 'none',
      fontSize: '9px',
      '@media(orientation: portrait)': {
        minWidth: '1px!important',
        height: 24,
        width: 24,
      },
      '&:before': {
        content: '"\\e903"',
      },
      '& .MuiBadge-anchorOriginTopRightRectangle': {
        marginTop: '-11px',
        marginRight: '-5px',
      },
    },
    buttonProps: {
      onClick: () => {
        setIsSurveyOpen(true);
        setBadgeCount(null);
        dispatchEvent(somaEvents.onChatShown);
      },
    },
  };

  const pipButtonProps = {
    figure: <img src={theme.pictureInPicture.icon} alt="pip" />,
    buttonStyles: { ...pipButtonClasses },
    buttonProps: {
      onClick: () => {
        dispatchEvent(somaEvents.onActivePip);
      },
    },
  };

  const reactionButtonProps = {
    figure: <ReactionAssets src={theme.reaction.icons.outlinedHeart} alt="heart" refTarget={reactionRef} />,
    buttonStyles: { ...reactionButtonClasses },
    buttonProps: {
      onClick: () => {
        if (reacted) return;

        dispatchEvent(somaEvents.heartsUp, {
          showCasing: realTimeProductsState.showCasing.map((obj) => obj.productName).join(' / '),
        });
        dispatchEvent('myReaction');
        dispatchEvent('heartsUp');
        setReacted(true);

        setTimeout(() => {
          setReacted(false);
        }, 2000);
      },
      ref: reactionRef,
    },
  };

  const productDetailsDrawers = {
    desktop: {
      setIsOpen: setIsProductDetailsDrawerOpen,
      isOpen: isProductDetailsDrawerOpen,
      padding: theme.drawer.padding,
      anchorDirection: 'left',
      drawerStyles: {
        width: '901px',
        left: '10%',
      },
    },
    mobile: {
      setIsOpen: setIsProductDetailsDrawerOpen,
      isOpen: isProductDetailsDrawerOpen,
      anchorDirection: 'bottom',
      drawerStyles: {
        borderRadius: theme.pdp.mobileBorderRadius,
        maxHeight: '95%',
        width: '100%',
        padding: 0,
        left: 0,
      },
    },
  };

  const productDetailsProps = {
    drawer: portrait
      ? { ...productDetailsDrawers.mobile }
      : { ...productDetailsDrawers.desktop, closeSelfButton: true },
    component: {
      product: productDetails.product,
      category: productDetails.category,
      closeDrawer: () => {
        setIsProductDetailsDrawerOpen(false);
      },
    },
  };

  // const somaTubeProps = {
  //   rerunStartTime: video.rerunStartTime,
  //   isRerun: video.isRerun,
  //   url: video.url,
  //   setReacted,
  //   volume,
  //   playing: videoPlaying,
  // };

  const vimeoPlayerProps = {
    rerunStartTime: video.rerunStartTime,
    isRerun: video.isRerun,
    url: video.url,
    setReacted,
    volume,
    setVolume,
    isPlaying: videoPlaying,
    setIsPlaying: setVideoPlaying,
  };

  const muteButtonProps = {
    onClickMute: () => (volume === 1 ? setVolume(0) : setVolume(1)),
    isMuted: Boolean(volume),
  };

  const productListProps = {
    drawer: {
      type: 'productList',
      setIsOpen: setIsProductsDrawerOpen,
      padding: theme.drawer.padding,
      isOpen: isProductsDrawerOpen,
      width: theme.drawer.width,
      drawerStyles: {
        height: '100%',
        overflowY: 'hidden',
      },
    },
    component: {
      scrollTarget,
      buttons,
      user,
      columns: 2,
    },
  };

  const surveyProps = {
    drawer: {
      setIsOpen: setIsSurveyDrawerOpen,
      padding: theme.drawer.padding,
      isOpen: isSurveyDrawerOpen,
      width: '580px',
      drawerStyles: {
        maxHeight: '100%',
      },
      gridStyles: {
        flex: 1,
        display: 'flex',
        maxHeight: '100%',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    component: {
      closeFunction: () => {
        setIsSurveyDrawerOpen(false);
        setBadgeCount(null);
      },
    },
    mobile: {
      padding: theme.drawer.padding,
      setIsOpen: setIsSurveyOpen,
      isOpen: isSurveyOpen,
      width: '100%',
      anchorDirection: 'bottom',
      closeSelfButton: true,
      borderRadius: '16px 16px 0 0',
      maxHeight: '90%',
      drawerStyles: {
        height: '75%',
      },
      gridStyles: {
        flex: 1,
        maxHeight: '100%',
        flexDirection: 'column',
      },
    },
  };

  const anchorSizePorps = {
    drawer: {
      setIsOpen: setIsAnchorSizeDrawerOpen,
      padding: theme.drawer.padding,
      isOpen: isAnchorSizeDrawerOpen,
      width: '650px',
    },
    component: {
      closeFunction: () => {
        setIsAnchorSizeDrawerOpen(false);
      },
    },
  };

  // const shoesProps = {
  //   drawer: {
  //     setIsOpen: setShoesOpened,
  //     padding: theme.drawer.padding,
  //     isOpen: isShoesOpened,
  //     width: '500px',
  //   },
  //   component: {
  //     closeFunction: () => {
  //       setShoesOpened(false);
  //     },
  //   },
  // };

  const faqProps = {
    onClose: () => {
      setIsFaqOpen(false);
    },
    open: isFaqOpen,
    fullWidth: true,
    maxWidth: 'sm',
    overflow: 'auto',
  };

  const somaBagProps = {
    drawer: {
      setIsOpen: setIsBagDrawerOpen,
      padding: theme.drawer.padding,
      titleDrawer: theme.bag.text,
      isOpen: isBagDrawerOpen,
      width: 487,
    },
    component: {
      brand: brand.name,
      streamName,
      closeFunction: () => {
        setIsBagDrawerOpen(false);
        setIsProductsDrawerOpen(true);
      },
    },
  };

  const SomaMenuProps = {
    productText: theme.products.text,
    onClickProduct: () => {
      dispatchEvent(somaEvents.closeAllDrawers);
      setIsProductsDrawerOpen(true);
      dispatchEvent(somaEvents.onProductsShown);
    },
    bagText: theme.bag.text,
    onClickBag: () => {
      dispatchEvent(somaEvents.closeAllDrawers);
      setIsBagDrawerOpen(true);
      dispatchEvent(somaEvents.onCartShown);
    },
    surveyText: theme.survey.text,
    surveyOpen: isSurveyDrawerOpen,
    onClickSurvey: () => {
      dispatchEvent(somaEvents.closeAllDrawers);
      setIsSurveyDrawerOpen(true);
    },
    faqText: theme.faq.text,
    onClickFaq: () => {
      dispatchEvent(somaEvents.closeAllDrawers);
      setIsFaqOpen(true);
      dispatchEvent(somaEvents.onFaqShown);
    },
    // shoesText: theme.shoes.text,
    // onClickShoes: () => {
    //   dispatchEvent(somaEvents.closeAllDrawers);
    //   setIsFaqOpen(true);
    //   dispatchEvent(somaEvents.onFaqShown);
    // },
    anchorSizeText: theme.anchorSize.text,
    onClickAnchorSize: () => {
      dispatchEvent(somaEvents.closeAllDrawers);
      setIsAnchorSizeDrawerOpen(true);
      dispatchEvent(somaEvents.onAnchorSizeShown);
    },
  };

  const somaNavigationProps = {
    buttons: [
      {
        id_button: 'showcasing',
        label: theme.realtimeProduct.label,
        products: showCasing,
      },
      ...buttons,
    ],
    faqArgs: { noDialog: true },
    // shoesArgs: { noDialog: true },
    bagArgs: { brand: brand.name, streamName },
    scrollTarget: mobileScrollTarget,
    chatArgs: { chatMessages, user, email, name, sendMessage, saveUserData, setLoggedIn, loggedIn },
  };

  const somaChatProps = {
    open: isChatOpen,
    enteredOnChat: email,
    onClose: () => {
      setIsChatOpen(false);
    },
    raised: false,
    title: theme.chat.text,
  };

  return (
    <Box>
      {showWelcome && <HomePage {...homePageProps} />}
      <CartSimulationContext.Provider value={simulatedCart}>
        <LiveTemplate>
          <MobileShare key="mobileShare" />
          {videoPlaying && <MuteButton key="muteButton" {...muteButtonProps} />}
          <VimeoPlayer key="vimeoPlayer" {...vimeoPlayerProps} />
          <SomaNavigation key="menuMobile" {...somaNavigationProps} />
          <SomaMenu key="menuDesktop" {...SomaMenuProps} />
          {isChatEnabled && theme.chat.active && videoPlaying && <FigureButton key="chatButton" {...chatButtonProps} />}
          {isReactionEnabled && videoPlaying && <FigureButton key="reactionButton" {...reactionButtonProps} />}
          {isSurveyEnabled && isRerun === 0 && videoPlaying && (
            <FigureButton key="surveyButton" {...surveyButtonProps} badgeCount={isSurveyOpen ? null : badgeCount} />
          )}
          {showPiP && videoPlaying && <FigureButton key="pipButton" {...pipButtonProps} />}

          <LiveProduct key="showcasing" />

          <SomaDrawer key="productDetails" {...productDetailsProps.drawer}>
            <ProductDetails {...productDetailsProps.component} />
          </SomaDrawer>

          <SomaDrawer key="productsList" {...productListProps.drawer}>
            <SomaProductsList {...productListProps.component} />
          </SomaDrawer>

          <SomaDrawer key="somaBag" {...somaBagProps.drawer}>
            <SomaBag {...somaBagProps.component} />
          </SomaDrawer>

          <SomaDrawer key="survey" {...surveyProps.drawer}>
            <SomaSurvey {...surveyProps.component} />
          </SomaDrawer>

          <SomaDrawer key="anchorSize" {...anchorSizePorps.drawer}>
            <AnchorSize {...anchorSizePorps.component} />
          </SomaDrawer>

          {/* <SomaDrawer key="shoes" {...shoesProps.drawer}>
            <ShoesForm />
          </SomaDrawer> */}
        </LiveTemplate>

        {isSurveyEnabled && (
          <SomaDrawer {...surveyProps.mobile}>
            <SomaSurvey />
          </SomaDrawer>
        )}

        {isChatEnabled && <SomaChatCard {...somaChatProps}>{renderChatComponent()}</SomaChatCard>}

        <Dialog classes={dialogContainerClasses} {...faqProps}>
          {renderFaqComponent()}
        </Dialog>

        {theme.newFeatures.map((feature) => (
          <NewFeature key={feature.name} feature={feature} portrait={portrait} active={feature.active} />
        ))}
      </CartSimulationContext.Provider>
    </Box>
  );
};

export default Live;
