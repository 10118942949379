import { Grid, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useBagContext } from '../../../contexts/bagContext';
import { ComposedButton } from '../../molecules/SomaClickable';
import { useSocketContext } from '../../../contexts/socketContext';
import { useStreamContext } from '../../../contexts/streamContext';
import Styles from './styles';

const SomaMenu = (props) => {
  const {
    bagText,
    // shoesText,
    // onClickShoes,
    productText,
    surveyText,
    anchorSizeText,
    onClickBag,
    onClickProduct,
    onClickSurvey,
    onClickAnchorSize,
    surveyOpen,
    onClickFaq,
    faqText,
  } = props;
  const { bagBadgeCount } = useBagContext();
  const { surveyQuestion } = useSocketContext();
  const [badgeCount, setBadgeCount] = useState(null);

  const endedSurveys = surveyQuestion?.filter((survey) => !!Object.hasOwn(survey, 'active') && !survey.active) || [];
  const activeSurveys = surveyQuestion?.filter((survey) => survey.active) || [];

  useEffect(() => {
    if (activeSurveys.length > 0 || endedSurveys.length > 0) {
      setBadgeCount(1);
    }
  }, [activeSurveys.length, endedSurveys.length]);

  useEffect(() => {
    if (surveyOpen && endedSurveys.length > 0) {
      setBadgeCount(null);
    }
  }, [surveyOpen, endedSurveys.length]);

  const theme = useTheme();
  const { streamContent } = useStreamContext();
  const { brand, video, stream_name: streamName, has_measure: hasMeasure } = streamContent;
  const { isRerun } = video;
  const type = brand.name === 'animale' ? 'img' : 'figure';
  const anchorActiveSelector = hasMeasure ?? theme.anchorSize.active;
  const isAnchorEnabled = Boolean(anchorActiveSelector);

  return (
    <Grid container direction="column" alignItems="center" classes={Styles.useMainContainerStyles()}>
      <Grid item>
        <ComposedButton
          label={productText}
          figureChar="\e902"
          iconType={type}
          src={theme.products.icon.src}
          buttonProps={{ onClick: onClickProduct }}
          figureStyles={{ fontSize: 29 }}
        />
      </Grid>
      <Grid item>
        <ComposedButton
          label={bagText}
          badgeCount={bagBadgeCount}
          figureChar="\e900"
          iconType="img"
          src={theme.bag.icon.src}
          buttonProps={{ onClick: onClickBag }}
          figureStyles={{ fontSize: 32 }}
        />
      </Grid>
      {isRerun === 0 && streamName !== 'crisbarros-desfile-aw23' && (
        <Grid
          item
          onClick={() => {
            setBadgeCount(null);
          }}
        >
          <ComposedButton
            label={surveyText}
            figureChar="\e903"
            badgeCount={surveyOpen ? null : badgeCount}
            buttonProps={{ onClick: onClickSurvey }}
            figureStyles={{ fontSize: 24 }}
          />
        </Grid>
      )}
      <Grid item style={{ alignItems: 'flex-end' }}>
        <ComposedButton
          label={faqText}
          figureChar="\e901"
          iconType={type}
          src={theme.faq.icon.src}
          buttonProps={{ onClick: onClickFaq }}
          figureStyles={{ fontSize: 28 }}
        />
      </Grid>
      {/* <Grid item>
        <ComposedButton
          label={shoesText}
          iconType="img"
          src={theme.shoes.icon.src}
          buttonProps={{ onClick: onClickShoes }}
          figureStyles={{ fontSize: 32 }}
        />
      </Grid> */}
      <Grid item style={{ display: 'flex', flexGrow: 1, flexDirection: 'column-reverse' }}>
        {isAnchorEnabled && (
          <ComposedButton
            label={anchorSizeText}
            figureChar="\e906"
            iconType={type}
            src={theme.anchorSize.icon.src}
            buttonProps={{ onClick: onClickAnchorSize }}
            figureStyles={{ fontSize: 32 }}
          />
        )}
      </Grid>
    </Grid>
  );
};

SomaMenu.propTypes = {
  bagText: PropTypes.string.isRequired,
  productText: PropTypes.string.isRequired,
  surveyText: PropTypes.string.isRequired,
  onClickProduct: PropTypes.func.isRequired,
  onClickSurvey: PropTypes.func.isRequired,
  onClickBag: PropTypes.func.isRequired,
  onClickAnchorSize: PropTypes.func.isRequired,
};

export default SomaMenu;
