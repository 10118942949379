import TagManager from 'react-gtm-module';

import { useEffect } from 'react';
import { useEvent } from '../../events/useEvent';
import { somaEvents } from '../../events';
import { getMainProductInfos } from '../../helpers/eventHelper';

export default (props) => {
  const { somaLiveGtmId, brand, streamName } = props;

  const gtmEvents = [
    somaEvents.onEnter,
    somaEvents.onProductCategoryViewed,
    somaEvents.onProductsShown,
    somaEvents.onProductAddedToCart,
    somaEvents.onCheckoutStarted,
    somaEvents.onProductShowcasingClicked,
    somaEvents.onChatShown,
    somaEvents.onFaqShown,
    // somaEvents.onShoesShown,
    somaEvents.onAnchorSizeShown,
    somaEvents.onProductsFiltered,
    somaEvents.onProductsSearched,
    somaEvents.onCartSharingLinkCopied,
    somaEvents.onProductJumpedToTime,
    somaEvents.onProductMeasurementChartShown,
    somaEvents.onProductZoom,
    somaEvents.onRedirectHelpButton,
    somaEvents.onLiveShared,
    somaEvents.onLiveSharedAccessed,
    somaEvents.onShareProduct,
    somaEvents.heartsUp,
    somaEvents.onActivePip,
  ];

  gtmEvents.forEach((event) => {
    useEvent(event, (eventPayload) => {
      if (!TagManager.dataLayer) return;

      const adaptedPayload = getMainProductInfos(eventPayload);

      const dataLayer = {
        ...adaptedPayload,
        event,
        brand,
        streamName,
      };

      TagManager.dataLayer({ dataLayer });
    });
  });

  useEffect(() => {
    if (!somaLiveGtmId) return;
    TagManager.initialize({ gtmId: somaLiveGtmId });
  }, [somaLiveGtmId]);

  return null;
};
